exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articole-antidepresie-js": () => import("./../../../src/pages/articole/antidepresie.js" /* webpackChunkName: "component---src-pages-articole-antidepresie-js" */),
  "component---src-pages-articole-ascultarea-js": () => import("./../../../src/pages/articole/ascultarea.js" /* webpackChunkName: "component---src-pages-articole-ascultarea-js" */),
  "component---src-pages-articole-cazuistica-js": () => import("./../../../src/pages/articole/cazuistica.js" /* webpackChunkName: "component---src-pages-articole-cazuistica-js" */),
  "component---src-pages-articole-corpul-js": () => import("./../../../src/pages/articole/corpul.js" /* webpackChunkName: "component---src-pages-articole-corpul-js" */),
  "component---src-pages-articole-depresia-js": () => import("./../../../src/pages/articole/depresia.js" /* webpackChunkName: "component---src-pages-articole-depresia-js" */),
  "component---src-pages-articole-hipnoza-js": () => import("./../../../src/pages/articole/hipnoza.js" /* webpackChunkName: "component---src-pages-articole-hipnoza-js" */),
  "component---src-pages-articole-js": () => import("./../../../src/pages/articole.js" /* webpackChunkName: "component---src-pages-articole-js" */),
  "component---src-pages-articole-mituri-js": () => import("./../../../src/pages/articole/mituri.js" /* webpackChunkName: "component---src-pages-articole-mituri-js" */),
  "component---src-pages-articole-pierderea-js": () => import("./../../../src/pages/articole/pierderea.js" /* webpackChunkName: "component---src-pages-articole-pierderea-js" */),
  "component---src-pages-articole-prostia-js": () => import("./../../../src/pages/articole/prostia.js" /* webpackChunkName: "component---src-pages-articole-prostia-js" */),
  "component---src-pages-articole-psihologul-js": () => import("./../../../src/pages/articole/psihologul.js" /* webpackChunkName: "component---src-pages-articole-psihologul-js" */),
  "component---src-pages-articole-sectioon-js": () => import("./../../../src/pages/articole/sectioon.js" /* webpackChunkName: "component---src-pages-articole-sectioon-js" */),
  "component---src-pages-articole-stresul-js": () => import("./../../../src/pages/articole/stresul.js" /* webpackChunkName: "component---src-pages-articole-stresul-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-despre-js": () => import("./../../../src/pages/despre.js" /* webpackChunkName: "component---src-pages-despre-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-section-cazuistica-fr-js": () => import("./../../../src/pages/section/cazuisticaFr.js" /* webpackChunkName: "component---src-pages-section-cazuistica-fr-js" */),
  "component---src-pages-section-js": () => import("./../../../src/pages/section.js" /* webpackChunkName: "component---src-pages-section-js" */),
  "component---src-pages-servicii-js": () => import("./../../../src/pages/servicii.js" /* webpackChunkName: "component---src-pages-servicii-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

